import { ChangeEvent, useEffect, useState } from 'react';
import { FormFieldProps } from './types';
import { classNames } from '../../utils/string';

export interface CheckboxProps extends FormFieldProps {
  label: string;
  value: boolean;
  description?: string;
}

const Checkbox = (props: CheckboxProps) => {
  const [enabled, setEnabled] = useState(props.value || false);
  useEffect(() => {
    if (props.value !== enabled) {
      setEnabled(props.value);
    }
  }, [props.value]);

  const labelStyles = classNames(
    props.hasError && props.isDirty ? 'text-red-700' : '',
    'font-light'
  );

  const descriptionStyles = classNames(
    props.hasError && props.isDirty ? 'text-red-500' : 'text-gray-500',
    'font-medium'
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.checked;
    if (props.setFieldValue) {
      props.setFieldValue(props.name, val, true);
    } else if (props.setFieldTouched) {
      props.setFieldTouched(props.name, true, true);
    }
    setEnabled(val);
  };

  return (
    <fieldset className="space-y-5">
      <div className="relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id={`checkbox_${props.name}`}
            aria-describedby="checkbox-description"
            name={`checkbox_${props.name}`}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-trhp-teal focus:ring-trhp-teal"
            checked={enabled}
            onChange={onChange}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={`checkbox_${props.name}`} className={labelStyles}>
            {props.label}
          </label>
          {props.description && (
            <p id="checkbox-description" className={descriptionStyles}>
              {props.description}
            </p>
          )}
        </div>
      </div>
      {props.hasError && props.isDirty ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.errorMessage || `${props.label} is invalid`}
        </p>
      ) : null}
    </fieldset>
  );
};

export default Checkbox;

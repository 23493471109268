import { ChangeEvent, useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { FormFieldProps } from './types';
import { PatternFormat } from 'react-number-format';
import tw from 'tailwind-styled-components';
export interface TextFieldProps extends FormFieldProps {
  type?: string;
  autocomplete?: string;
  disabled?: boolean;
  customErrMessage?: string;
  placeholder?: string;
  useMask?: boolean;
  mask?: string;
}
type StyledFieldProps = {
  showerror?: string;
  type?: string;
  value?: string;
  name: string;
  disabled?: boolean;
  mask?: string;
  maskPlaceholder?: string;
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
};
const StyledField = tw.input<StyledFieldProps>`
  ${(props: StyledFieldProps) =>
    props.showerror
      ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500'
      : 'border-gray-200 shadow-sm focus:border-trhp-teal outline-none'}
  ${(props: StyledFieldProps) =>
    props.disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'}
  block w-full rounded-md  pr-5  focus:outline-none  sm:text-sm py-3
`;

const TextField = (props: TextFieldProps) => {
  const [value, setValue] = useState(props.value || '');
  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (props.setFieldValue) {
      props.setFieldValue(props.name, ev.target.value, false);
    }
    if (props.setFieldTouched) {
      props.setFieldTouched(props.name, true, false);
    }
    setValue(ev.target.value);
    if (props.hasError && props.isDirty && props.validate) {
      props.validate(props.name);
    }
  };

  const onBlur = () => {
    if (props.validate) {
      props.validate(props.name);
    }
  };

  return (
    <div>
      <label htmlFor={props.name} className="block text-sm font-medium">
        {props.label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        {props.useMask ? (
          <PatternFormat
            className={
              props.hasError
                ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 block w-full rounded-md  pr-5  focus:outline-none  sm:text-sm py-3'
                : 'border-gray-200 shadow-sm focus:border-trhp-teal outline-none block w-full rounded-md  pr-5  focus:outline-none  sm:text-sm p-3'
            }
            name={props.name}
            value={value}
            onChange={onChange}
            format={props.mask || ''}
            {...(props.placeholder && {
              placeholder: props.placeholder
            })}
            disabled={props.disabled || false}
          />
        ) : (
          <StyledField
            name={props.name}
            value={value}
            type={props.type || 'text'}
            showerror={props.hasError && props.isDirty ? 'true' : undefined}
            onChange={onChange}
            onBlur={onBlur}
            {...(props.placeholder && {
              placeholder: props.placeholder,
              alwaysShowMask: true
            })}
            disabled={props.disabled || false}
            {...(props.autocomplete && { autoComplete: props.autocomplete })}
          />
        )}
        {props.hasError && props.isDirty && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {props.hasError && props.isDirty ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.customErrMessage
            ? props.customErrMessage
            : props.errorMessage
            ? props.errorMessage
            : `${props.label} is invalid`}
        </p>
      ) : null}
    </div>
  );
};

export default TextField;

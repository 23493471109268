import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { FormFieldProps } from './types';
import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { classNames } from '../../utils/string';
import tw from 'tailwind-styled-components';

export interface SelectProps extends Omit<FormFieldProps, 'label'> {
  label?: string;
  placeholder: string;
  options: { id: string; name: string }[];
  disabled?: boolean;
  defaultValue?: string;
}
type StyledFieldProps = {
  showerror?: string;
  children: React.ReactNode;
  disabled?: boolean;
  placeholder: string;
  as?: string;
  role?: string;
  tabIndex?: string;
};
const StyledSelectField = tw(Listbox.Button)<StyledFieldProps>`
  ${(props: StyledFieldProps) =>
    props.showerror
      ? 'border border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
      : 'border border-gray-300 bg-white pl-3 pr-10 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500'}
      rounded-md relative w-full cursor-default sm:text-sm text-left block py-3 px-2
`;
const StyledOptionsField = tw(Listbox.Options)`
absolute z-[1000] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`;

const Select = (props: SelectProps) => {
  const [value, setValue] = useState<string>(
    props.value !== '' ? props.value : props.defaultValue ?? ''
  );
  const [label, setLabel] = useState<string>('');
  useEffect(() => {
    if (props.value && props.value !== value && props.value !== '') {
      setValue(props.value);
      const selectedOption = props.options?.find(
        (o: { id: string; name: string }) => o.id === props.value
      );
      if (selectedOption) {
        setLabel(selectedOption.name);
      }
    } else if (!label) {
      const selectedOption = props.options?.find(
        (o: { id: string; name: string }) => o.id === props.value
      );
      if (selectedOption) {
        setLabel(selectedOption.name);
      }
    } else if (
      props.defaultValue &&
      props.defaultValue !== '' &&
      value === ''
    ) {
      setValue(props.defaultValue);
      if (props.setFieldValue)
        props.setFieldValue(props.name, props.defaultValue, true);
      const selectedOption = props.options?.find(
        (o: { id: string; name: string }) => o.id === props.defaultValue
      );
      if (selectedOption) {
        setLabel(selectedOption.name);
      }
    }
    if (value && !label) {
      const selectedOption = props.options?.find(
        (o: { id: string; name: string }) => o.id === value
      );
      if (selectedOption) {
        setLabel(selectedOption.name);
      }
    }
  }, [props.value, props.defaultValue]);

  const onChange = (val: string) => {
    if (props.setFieldValue) {
      props.setFieldValue(props.name, val, true);
    } else if (props.setFieldTouched) {
      props.setFieldTouched(props.name, true, true);
    }
    setValue(val);
    const selectedOption = props?.options?.find(
      (o: { id: string; name: string }) => o.id === val
    );
    if (selectedOption) {
      setLabel(selectedOption.name);
    }
  };

  return (
    <div>
      <div className="relative rounded-md shadow-sm">
        <Listbox value={value} name={props.name} onChange={onChange}>
          {({ open }) => (
            <>
              {props.label && (
                <Listbox.Label className="block text-sm font-medium">
                  {props.label}
                </Listbox.Label>
              )}

              <div className="relative mt-1">
                <StyledSelectField
                  showerror={
                    props.hasError && props.isDirty ? 'true' : undefined
                  }
                  placeholder={props.placeholder || ''}
                  disabled={Boolean(props.disabled)}
                  as="a"
                  tabIndex="0"
                  role="button"
                >
                  <span className="block truncate">
                    {label || props.placeholder}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </StyledSelectField>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <StyledOptionsField>
                    {props.options.map((option, ind) => (
                      <Listbox.Option
                        key={ind}
                        disabled={Boolean(props.disabled)}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-blue-500' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={option.id}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {option.name}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-blue-500',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </StyledOptionsField>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
      {props.hasError && props.isDirty ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.errorMessage || `${props.label} is invalid`}
        </p>
      ) : null}
    </div>
  );
};

export default Select;
